export var block = function () {
    console.log('called block from app.ts');
    var height = window.innerHeight;
    var width = window.innerWidth;
    jQuery.blockUI({
        message: '<img src="../img/loading-spinner.svg" style="width: 70px; height: 70px;"/>',
        css: {
            top: (height - 70) / 2 + 'px',
            left: (width - 70) / 2 + 'px',
            width: '70px',
            backgroundColor: 'rgba(0,0,0,0)',
            border: 'none'
        }
    });
};
export function initLoaders() {
    //@ts-ignore
    window.block = block;
    document.addEventListener('DOMContentLoaded', function () {
        // doesn't work for whatever reason, will have to put in a scripts view inlined
        // @ts-ignore
        Livewire.on('unblock', function () {
            console.log('called unblock from app.ts');
            $.unblockUI();
        });
    });
}
