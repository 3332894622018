import Cookies from 'js-cookie';
function getIsCompact() {
    return localStorage.getItem('sidebar-compact') === 'true' || Cookies.get('sidebar-compact') === 'y';
}
function setIsCompact(isCompact) {
    localStorage.setItem('sidebar-compact', isCompact ? 'true' : 'false');
    Cookies.set('sidebar-compact', isCompact ? 'y' : 'n', { sameSite: 'Lax' });
}
function initCompactSidebarToggle() {
    var sidebar = document.querySelector('#sidebar-desktop');
    var isCompact = getIsCompact();
    if (isCompact) {
        sidebar === null || sidebar === void 0 ? void 0 : sidebar.classList.add('sidebar--compact');
    }
    function toggleCompact() {
        sidebar === null || sidebar === void 0 ? void 0 : sidebar.classList.toggle('sidebar--compact');
        var isCompact = sidebar === null || sidebar === void 0 ? void 0 : sidebar.classList.contains('sidebar--compact');
        setIsCompact(isCompact);
        var hoverWrapper = document.querySelector('.sidebar__hover-wrapper');
        if (hoverWrapper) {
            hoverWrapper.classList.add('hidden');
        }
    }
    function addListeners() {
        var sidebarToggle = document.querySelector('#sidebar-toggle-desktop');
        sidebarToggle === null || sidebarToggle === void 0 ? void 0 : sidebarToggle.addEventListener('click', toggleCompact);
    }
    addListeners();
}
export function compactDrawer() {
    initCompactSidebarToggle();
    var sidebar = document.querySelector('#sidebar-desktop');
    var hoveredElement = null;
    var hoverWrapper = null;
    var navigationElements = sidebar === null || sidebar === void 0 ? void 0 : sidebar.querySelectorAll('nav > div > button');
    var hoverExitTimeout;
    function isCompact() {
        return sidebar === null || sidebar === void 0 ? void 0 : sidebar.classList.contains('sidebar--compact');
    }
    function createHoverWrapper() {
        hoverWrapper = document.createElement('div');
        hoverWrapper.classList.add('sidebar__hover-wrapper');
        hoverWrapper.classList.add('hidden');
        sidebar === null || sidebar === void 0 ? void 0 : sidebar.appendChild(hoverWrapper);
        hoverWrapper.addEventListener('mouseleave', function () {
            if (hoveredElement) {
                hoveredElement.classList.remove('hover');
            }
            navigationElements === null || navigationElements === void 0 ? void 0 : navigationElements.forEach(function (element) {
                element.classList.remove('hover');
            });
            hoverWrapper === null || hoverWrapper === void 0 ? void 0 : hoverWrapper.classList.add('hidden');
            onHoverExit(hoveredElement);
        });
        hoverWrapper.addEventListener('mouseenter', function () {
            clearTimeout(hoverExitTimeout);
        });
    }
    createHoverWrapper();
    function showHoverWrapper() {
        var _a, _b, _c, _d;
        // delete children
        while (hoverWrapper === null || hoverWrapper === void 0 ? void 0 : hoverWrapper.firstChild) {
            hoverWrapper.removeChild(hoverWrapper.firstChild);
        }
        // copy children from hovered element
        var header = document.createElement('h4');
        if (header) {
            header.innerText = (_a = ((hoveredElement === null || hoveredElement === void 0 ? void 0 : hoveredElement.textContent) && hoveredElement.textContent.trim())) !== null && _a !== void 0 ? _a : '';
        }
        hoverWrapper === null || hoverWrapper === void 0 ? void 0 : hoverWrapper.appendChild(header);
        var list = document.createElement('div');
        var sibling = hoveredElement === null || hoveredElement === void 0 ? void 0 : hoveredElement.nextElementSibling;
        for (var i = 0; i < ((_c = (_b = sibling === null || sibling === void 0 ? void 0 : sibling.children) === null || _b === void 0 ? void 0 : _b.length) !== null && _c !== void 0 ? _c : 0); i++) {
            var child = sibling === null || sibling === void 0 ? void 0 : sibling.children[i];
            if (child)
                list.appendChild(child.cloneNode(true));
        }
        hoverWrapper === null || hoverWrapper === void 0 ? void 0 : hoverWrapper.appendChild(list);
        var rect = (_d = hoveredElement === null || hoveredElement === void 0 ? void 0 : hoveredElement.getBoundingClientRect()) !== null && _d !== void 0 ? _d : { top: 0, right: 0 };
        if (hoverWrapper) {
            hoverWrapper.style.top = "".concat(rect.top - 1, "px");
            hoverWrapper.style.left = "".concat(rect.right, "px");
            if (hoverWrapper.classList.contains('hidden')) {
                hoverWrapper.classList.remove('hidden');
            }
        }
    }
    function onHover(el) {
        clearTimeout(hoverExitTimeout);
        if (isCompact()) {
            if (hoveredElement && hoveredElement !== el) {
                hoveredElement.classList.remove('hover');
                hoverWrapper === null || hoverWrapper === void 0 ? void 0 : hoverWrapper.classList.add('hidden');
            }
            hoveredElement = el;
            el === null || el === void 0 ? void 0 : el.classList.add('hover');
            showHoverWrapper();
        }
    }
    function onHoverExit(el, timeout) {
        if (timeout === void 0) { timeout = 100; }
        if (isCompact()) {
            hoverExitTimeout = setTimeout(function () {
                hoveredElement = null;
                if (el) {
                    el.classList.remove('hover');
                }
                if (!(hoverWrapper === null || hoverWrapper === void 0 ? void 0 : hoverWrapper.classList.contains('hidden'))) {
                    hoverWrapper === null || hoverWrapper === void 0 ? void 0 : hoverWrapper.classList.add('hidden');
                }
            }, timeout);
        }
    }
    function addListeners() {
        navigationElements === null || navigationElements === void 0 ? void 0 : navigationElements.forEach(function (element) {
            element.addEventListener('mouseenter', function (ev) {
                ev.preventDefault();
                onHover(element);
            });
            element.addEventListener('mouseleave', function (ev) {
                ev.preventDefault();
                onHoverExit(element);
            });
            element.addEventListener('click', function (ev) {
                if (isCompact()) {
                    ev.preventDefault();
                    if (element && element.classList.contains('hover')) {
                        onHoverExit(element, 0);
                    }
                    else {
                        onHover(element);
                    }
                }
            });
        });
    }
    addListeners();
}
