function initMobileDrawerToggle(drawer) {
    var sidebarToggle = document.querySelector('#sidebar-toggle-mobile');
    sidebarToggle === null || sidebarToggle === void 0 ? void 0 : sidebarToggle.addEventListener('click', function () {
        drawer.toggle();
    });
    var sidebarClose = document.querySelector('#sidebar-mobile-close');
    sidebarClose === null || sidebarClose === void 0 ? void 0 : sidebarClose.addEventListener('click', function () {
        drawer.toggle();
    });
}
export function mobileDrawer() {
    var sidebar = document.querySelector('#sidebar-mobile');
    var options = {
        placement: 'left',
        backdrop: true,
        bodyScrolling: false,
        edge: false,
        edgeOffset: '',
        backdropClasses: 'bg-gray-900 bg-opacity-50 dark:bg-opacity-80 fixed inset-0 z-30',
        onHide: function () {
            console.log('drawer is hidden');
        },
        onShow: function () {
            console.log('drawer is shown');
        },
        onToggle: function () {
            console.log('drawer has been toggled');
        }
    };
    try {
        // @ts-ignore
        var drawer = new Drawer(sidebar, options);
        sidebar === null || sidebar === void 0 ? void 0 : sidebar.classList.remove('hidden');
        initMobileDrawerToggle(drawer);
    }
    catch (error) {
        console.log(error);
    }
}
