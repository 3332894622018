import { bootstrap } from './init/bootstrap';
import Alpine from 'alpinejs';
import { mobileDrawer } from './drawer';
import { compactDrawer } from './drawer/compactDrawer';
import { initLoaders } from './utils/loaders';
window.onload = function () {
    bootstrap();
    // @ts-ignore
    window.Alpine = Alpine;
    Alpine.start();
    mobileDrawer();
    compactDrawer();
    initLoaders();
};
